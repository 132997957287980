import React from "react"
import Seo from "../seo"
import Form from "../Form"
import Layout from "../Layout"
import Banner from "../Banner"
import Heading from "../Heading"
import BaseColumn from "../MainColumn"
import styled from "styled-components"
import http from "../../utils/httpService"
import processData from '../../utils/processData'
import { useStaticQuery, graphql } from "gatsby"

const MainColumn = styled(BaseColumn)`
  padding: 6.4rem 0;
`

const formData = [
  {
    content: [
      { key: "name", title: "Nome completo", required: true },
      { key: "email", title: "E-mail", required: true },
      { key: "ddd", title: "DDD", width: 15 },
      { key: "phone", type: "phone", title: "Telefone", width: 80 },
      { key: "text", type: "textarea", title: "Texto", required: true }
    ]
  }
]

function Contact() {
  const { file } = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "contact-banner-image.png" }) {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const handleSubmit = values => {
    const content = processData(values, formData)
    const data = { from: values.email, content, subject: `Novo contato: ${values.email}` }

    return http.post("/send-email", { data })
  }

  return (
    <Layout>
      <Seo title="Entre em contato" />
      <Banner file={file} />
      <MainColumn>
        <div id="content">
          <Heading
            title="Contato"
            description="Entre em contato caso tenha sugestões e dúvidas"
          />
          <Form handleSubmit={handleSubmit} render={formData} />
        </div>
      </MainColumn>
    </Layout>
  )
}

export default Contact
